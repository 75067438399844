.club-information {
  min-height: 300px;
  width: 90%;
  margin: 40px auto;
}

.club-information .club-info-img {
  text-align: right;
  margin-top: 50px;
  position: relative;
  width: 120px;
  height: 120px;
  float: right;
  margin-right: 30px;
  overflow: hidden;
  border-radius: 66px;
}

.club-information .club-info-img button {
  height: 100%;
}

.club-information .club-info-img img {
  width: 100%;
  height: auto;
  text-align: center;
}

.club-information .club-info-img button {
  border: none;
  background-color: transparent;
}

.club-information .club-info-para {
  text-align: left;
  width: 100%;
}

.club-information .club-info-para .heading {
  text-align: center;
  color: #a32517;
  font-weight: bold;
  margin-bottom: 20px;
}

.club-information .club-info-para .club-info-para-1 {
  font-size: 13px;
}

.club-information .club-info-para .club-info-para-2 {
  font-size: 13px;
}

.club-information .club-info-para .info-btn {
  background-color: #c4c4c4;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  padding: 1px 10px;
  border: none;
  border-radius: 4px;
}

.club-information .club-info-para .info-btn i {
  margin-right: 5px;
}

.club-info-form .form-control-select {
  display: block;
  width: 65%;
  margin-top: 25px;
  color: #75757d !important;
  box-shadow: 2px 2px 4px #888888;
  border: 1px solid #d7d7d7;
  padding: 4px;
  font-size: 15px;
  letter-spacing: 1px;
  color: #bdbcbc !important;
  outline: none;
}

.club-info-form .form-control-select option {
  color: #858585;
}

.club-info-form-2 {
  margin-top: 20px;
}

.club-info-form #name {
  width: 100%;
  color: #75757d !important;
  box-shadow: 2px 2px 3px #888888;
  border: 1px solid #d7d7d7;
  border-radius: 0px;
  padding: 3px 3px !important;
  color: #bdbcbc !important;
  outline: none;
}

.club-info-form #number {
  width: 100%;
  color: #75757d !important;
  box-shadow: 2px 2px 3px #888888;
  border: 1px solid #d7d7d7;
  border-radius: 0px;
  padding: 3px 3px !important;
  color: #bdbcbc !important;
  outline: none;
}

.club-information .club-info-para .club-info-btn {
  display: block;
  width: 90%;
  background-color: #f64c72;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  /* margin: 100px auto; */
  padding: 3px 10px;
  border: none;
  margin-top: 40px;
  border-radius: 0px;
  letter-spacing: 1px;
}

.club-information .club-info-para .club-info-btn-back {
  display: block;
  /* width: 90%; */
  background-color: transparent;
  color: black;
  font-size: 15px;
  font-weight: 600;
  /* margin: 100px auto; */
  padding: 3px 10px;
  border: none;
  border-radius: 0px;
  float: right;
  margin-top: 40px;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .club-information .club-info-img {
    width: 100%;
    text-align: center;
  }

  .club-information .club-info-para .heading {
    position: relative;
    top: -160px;
  }

  .club-info-form .form-control-select {
    width: 100%;
  }

  .club-info-form #name {
    margin: auto;
  }

  .club-info-form #number {
    margin: auto;
  }
}
@media (max-width: 567px) {
  .club-information .club-info-img {
    width: 100%;
    text-align: center;
  }

  .club-information .club-info-para .heading {
    position: relative;
    top: -160px;
  }

  .club-info-form .form-control-select {
    width: 100%;
  }

  .club-info-form #name {
    margin: auto;
  }

  .club-info-form #number {
    margin: auto;
  }
}
