#UserProfile {
  margin: auto;
  margin-top: 15px;
  width: 95vw;
  margin-bottom: 50px;
  background-color: white;
  min-height: 760px;
  /* padding: 20px; */
}

.Profile-parent {
  max-width: 900px;
  margin: auto;
  padding-top: 50px;
}

@media (max-width: 567px) {
  .Profile-parent {
    padding-top: 0px;
  }
}

/*# sourceMappingURL=UserProfile.css.map */
