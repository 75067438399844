.Dogs {
  min-height: 300px;
  width: 90%;
  margin: auto;
}

.Dogs .dogs-parent {
  overflow: hidden;
}

.Dogs .add-btn {
  background-color: transparent;
  border: none;
  margin-top: 20px;
  position: relative;
  float: right;
}

.Dogs .add-btn i {
  color: #f64c72;
  font-size: 35px;
}

.Dogs .para {
  color: #2a3166;
  text-align: center;
  margin-top: 50px;
}

@media only screen and (max-width: 567px) {
  .Dogs {
    width: 90%;
  }
}

/*# sourceMappingURL=Dogs.css.map */
