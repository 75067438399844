.UsernameModal {
}
.modal-container {
  position: relative;
  /* padding: 20px; */
}

.username-list{

    overflow-y: auto;
    min-height: 300px;

}
.modal-container h1 {
  color: var(--dark2-color);
  font-size: 20px;
}

.modal-container .close-btn {
  color: var(--cta-color);
  font-size: 20px;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
}

.modal-container .username-img-div {
  width: 45px !important;
  height: 45px !important;
  /* margin-top: 10px; */
  overflow: hidden;
  text-align: center;
  /* float: left; */
  /* margin-left: 20px; */
  border-radius: 50%;
}

.modal-container .username-img-div button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  border: none;
  background-color: black;
}

.modal-container .username-img-div img {
  margin: 0px !important;
  width: 100% !important;
  height: auto;
}

.modal-container .username-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container .names {
  flex-grow: 1;
  align-items: center;
  justify-content: center;

}

.modal-container hr{
    margin: 10px 0px!important;
}
.modal-container .names  a{
    color: var(--dark2-color);
    text-decoration: none;
    font-weight: 600;
    margin-left: 10px;
    /* padding-top: 20px; */
  }
  