

.DogX {
  min-height: 80px;
  text-align: left;
  margin: auto;
  overflow: hidden;
  position: relative;
  border: 2px solid var(--dark2-color);
  border-radius: 8px;
}

.DogX .leftInfo {
  padding: 30px;
  height: 100%;
  background-color: var(--dark2-color)!important;
  border-radius: 6px;
}

.DogX .leftInfo .name {
  font-weight: 600;
  color: white;
  font-size: 20px;
  display: inline-block;
}

.DogX .leftInfo .edit-btn {
  font-weight: 600;
  color: white !important;
  font-size: 18px;
  padding: 2px 5px;
  float: right;
  text-decoration: none;
  font-size: 14px;
  border-radius: 3px;
  background-color: var(--cta-color);
}

.DogX .leftInfo .breed-info p {
  color: white;
  font-size: 15px;
  margin-bottom: 0px;
}

.DogX .leftInfo .breed-info p span {
  font-weight: 600;
}

.DogX .main-badge-container {
  padding: 30px;
  padding-left: 30px;
  /* padding-right: 30px; */
  background-color: white;
  overflow-y: auto;
  max-height: 360px;
  height: 100%;
  position: relative;
  /* margin-left: 5px; */

}

.DogX .main-badge-container::-webkit-scrollbar {
  width:16px;
}
.DogX .main-badge-container::-webkit-scrollbar-track {
  background: lightgray;
}
.DogX .main-badge-container::-webkit-scrollbar-thumb {
  background-color: var(--dark2-color) ;
  border-radius: 6px;
  /* border: 3px solid var(--dark-color); */
}



.DogX .org-container .organization {
  font-weight: 600;
}

.DogX .org-container .program {
  font-weight: 700;
  margin-bottom: 5px;
}
.DogX .org-container .icon {
  color: var(--cta-color);
}


.DogX .org-container .name {
  color: var(--dark2-color);
  font-weight: 500;
}

.DogX .main-badge-container .count-badge{

  position: absolute;
  right: 20px;
  top: 10px;
  color: var(--dark2-color);
  font-size: 24px;
  font-weight: 700;
}
.DogX .main-badge-container .count-badge .icon{
  color: var(--cta-color);
  /* font-size: 30px; */

}

.DogX .like-info p{
  color: white;
  font-size: 14px;
  margin-top: 0px;
}

.DogX .like-info .icon{

  color: var(--cta-color);
  font-size: 26px;
  float: left;
  margin-right: 10px;
  cursor: pointer;;
}


.DogX .like-info .multiname{
  display: inline-block;
  margin-bottom: 0px;
  /* line-height: 2em; */
  float: left;
color: white;
}


.DogX .like-info .multiname strong{
  cursor: pointer;
}


.DogX .like-info .multiname a{
  color: white;
  text-decoration: none;
}



.DogX .dog-img-div {
  width: 85px !important;
  height: 85px !important;
  display: inline-block;
  /* margin-top: 10px; */
  overflow: hidden;
  text-align: center;
  float: left;
  /* margin-left: 20px; */
  border-radius: 50%;
}



.DogX .dog-img-div button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  border: none;
  background-color: black;
}

.DogX .dog-img-div img {
  margin: 0px !important;
  width: 100% !important;
  height: auto;
}

.DogX .hover {
  display: none;
  background-color: var(--dark-color);
  z-index: 12;
  padding-top: 10px;
  transition: all 1s ease-in;
  width: 70px;
  right: -50px;
  text-align: center;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  height: 100%;
  border-radius: 20px 5px 5px 20px;
}

.DogX .hover .edit {
  background-color: var(--third-color);
  color: white;
  padding: 1px 5px;
}

.Dog:hover .hover {
  display: block;
  position: absolute;
  right: 0px;
}

.DogX .dog-data {
  display: inline-block;
  width: calc(100% - 200px) !important;
  text-align: left;
  position: relative;
  top: 15px;
  left: 10px;
}

.DogX .dog-data .heading {
  color: #7c7c7c;
  font-size: 18px;
  margin-bottom: 0%;
}

.DogX .dog-data .name {
  display: inline-block;
}

.DogX .dog-data .age {
  display: inline-block;
}

.DogX .name-age {
  color: #7e7e7e;
  font-size: 15px;
  font-weight: 600;
}

.DogX .dog-medal {
  display: inline-block;
  width: 100px;
  color: #242582;
  font-size: 23px;
  font-weight: bold;
  position: relative;
  text-align: right;
}

.DogX .dog-medal i {
  font-size: 24px;
  color: #c4c4c4;
}

/*# sourceMappingURL=Dog.css.map */
