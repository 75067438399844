.Forget {
  text-align: center;
  margin-top: 15px;
  background-color: #ffff;
  max-width: 95%;
  min-height: 760px;
}

.Forget-container {
  position: relative;
  top: 44px;
}

.Forget-container h6 {
  color: #16a1a5;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 25px;
}

.Forget-container input {
  box-shadow: 0.5px 3px 2px #888888;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  padding: 5px;
  font-size: 15px;
  letter-spacing: 1px;
  outline: 0px;
  color: black !important;
}

::placeholder {
  color: #c5c4c4;
}

.Forget-form {
  text-align: center;
  margin: auto;
  max-width: 350px;
}

.Forget-container .btn {
  display: block;
  background-color: #f64c72;
  color: white !important;
  margin: auto;
  padding: 6px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  box-shadow: 0px 0px;
}

.Forget-container a {
  font-size: 15px;
  margin-bottom: 100px;
  font-weight: 500;
  color: #9a9797;
  list-style: none;
}

.Forget-container p {
  margin-top: 36px;
  color: #9a9797;
  font-weight: 500;
}

.Forget-container a span {
  margin: 100px auto;
  color: #16a1a5;
  font-size: 16px;
  margin-left: 5px;
  font-weight: bold;
}

/*# sourceMappingURL=Forget.css.map */
