.ProfileBottom .tabs {
  margin-top: 30px;
}

.ProfileBottom .tabs .tab-buttons .tab-btn {
  width: calc(33.33% - 0.75px);
  border: none;
  padding: 5px 10px;
  background-color: #242582;
  color: white;
  font-weight: 600;
}

.ProfileBottom .tabs .tab-buttons .follower-btn,
.ProfileBottom .tabs .tab-buttons .event-btn {
  margin-left: 1px;
}

.ProfileBottom .tabs .tab-buttons .active {
  background-color: #242582;
}

.ProfileBottom .tabs {
  margin-top: 30px;
}

.ProfileBottom .tabs .tab-buttons .tab-btn {
  width: calc(25% - 0.75px);
  border: none;
  padding: 5px 10px;
  background-color: rgba(42, 49, 102, 0.5);
  color: white;
  font-weight: 600;
}

.ProfileBottom .tabs .tab-buttons .follower-btn,
.ProfileBottom .tabs .tab-buttons .event-btn,.ProfileBottom .tabs .tab-buttons .following-btn {
  margin-left: 1px;
}

.ProfileBottom .tabs .tab-buttons .active {
  background-color: #242582;
}

/*# sourceMappingURL=UserProfileBottom.css.map */

@media screen and (max-width:800px) {

  .ProfileBottom .tabs .tab-buttons .tab-btn {
    width: calc(50% - 2px)!important;
    border: none;
    padding: 5px 10px;
    background-color: rgba(42, 49, 102, 0.5);
    color: white;
    font-weight: 600;
  }
 
  .ProfileBottom .tabs .tab-buttons .follower-btn,
  .ProfileBottom .tabs .tab-buttons .dog-btn,
.ProfileBottom .tabs .tab-buttons .event-btn,
.ProfileBottom .tabs .tab-buttons .following-btn {
  
  margin: 1px;
}
}
