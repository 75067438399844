#Landing {
  background-color: #ffffff;
  padding-bottom: 1vh;
}

.carousel-inner-text {
  position: absolute;
  top: 20%;
  left: 10%;

  max-width: 25vw;
margin: auto;
}
