#SettingAndBillingTabs {
  background-color: #fff;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 50px;
  width: 95vw;
  min-height: 760px;
}

#SettingAndBillingTabs .setting-parent {
  max-width: 900px;
  margin: auto;
  padding-top: 50px;
}

@media (max-width: 567px) {
  #SettingAndBillingTabs .setting-parent {
    padding-top: 0px;
  }
}

/*# sourceMappingURL=SettingAndBillingTabs.css.map */
