.SearchItem {
  margin: 5px;
  margin-top: 0px;
  position: relative;
}

.SearchItem .item-container {
  background-color: rgba(36,37,130,0.3);
  padding: 5px;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.SearchItem .search-item-img {
  display: inline-block;
  width: 110px;
}

.SearchItem img {
  margin-top: -45px;
  margin-left: 15px;
  width: 70px;
  height: 70px;
  border-radius: 40px;
}

.SearchItem .profile-data {
  display: inline-block;
}

.SearchItem .profile-data .data-container {
  margin-top: 10px;
  position: relative;
  right: 15px;
}

.SearchItem .profile-data .heading {
  margin-bottom: 0px;
  color: #7f7f7f;
  font-weight: bold;
  font-size: 16px;
}

.SearchItem .profile-data .email {
  color: #7f7f7f;
  font-weight: bold;
  font-size: 14px;
}

.SearchItem .profile-data i {
  color: #c5c5c5;
  font-size: 12px;
}

.SearchItem .profile-data .medal {
  display: inline-block;
  color: #242582;
  font-weight: bold;
  font-size: 12px;
}

.SearchItem .profile-data .paw {
  display: inline-block;
  margin-left: 10px;
  color: #242582;
  font-weight: bold;
  font-size: 12px;
}

.SearchItem .profile-data .user {
  display: inline-block;
  margin-left: 10px;
  color: #242582;
  font-weight: bold;
  font-size: 12px;
}

.follow-btnn .btn {
  background-color: #f64c72;
  font-weight: 500;
  border-radius: 6px;
  border: none;
  outline: none;
  color: #fff;
  padding: 1px 25px;
  position: absolute;
  top: 25px;
  right: 10px;
  width: 120px;
  text-align: center;
}

.follow-btnn .followed {
  background-color: gray !important;
}

@media screen and (max-width: 800px) {
  .SearchItem {
    margin-bottom: 1px;
  }

  .follow-btnn {
    width: 30px;
    top: 20px;
    right: 10px;
  }

  .follow-btnn .btn {
    display: none;
  }

  .follow-btnn .btn-small {
    background-color: #f64c72;
    display: block;
    font-weight: 500;
    border-radius: 50%;
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    line-height: 0em;
    font-size: 20px;
    color: #fff;
    padding: 0px 1px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

/*# sourceMappingURL=SearchItem.css.map */
