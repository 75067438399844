#SearchPage {
  margin: auto;
  margin-top: 15px;
  width: 95vw;
  margin-bottom: 50px;
  background-color: white;
  min-height: 760px;
}

.search-box {
  max-width: 800px;
  margin: auto;
  margin-top: 20px;
}

.search-box .search-input {
  margin: 10px auto;
  margin-bottom: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-box input {
  border: 2px solid #eee;
  padding: 10px 10px 10px 55px;
  font-size: 23px;
  line-height: 30px;
  outline: 0;
  display: block;
  width: 100%;
  background-color: #e3e3e3;
  color: #7c7c7c;
  font-weight: 500;
  caret-color: #fff;
}

::placeholder {
  color: #fff;
}

.search-box .icon {
  position: absolute;
  left: 15px;
  top: 0;
  font-size: 25px;
  color: #ffff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchitems-box {
  max-width: 800px;
  min-height: 120px;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.user-list {
  max-width: 800px;
  margin: auto;
}

.searchitems-box .search-item-empty {
  width: 100%;
  border-bottom: 2px solid #eee;
  min-height: 60px;
}

@media screen and (max-width: 768px) {
  /* .search-box .icon {
    font-size: 20px;
  } */
}
@media screen and (max-width: 567px) {
  .search-box {
    min-width: 95vw;
  }

  .search-box .container {
    width: 100%;
    margin-top: 0px;
  }

  .search-box .search-input {
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
  }

  .search-box input {
    font-size: 20px;
    line-height: 30px;
    width: 100%;
    background-color: #e3e3e3;
    color: #7c7c7c;
    font-weight: 500;
  }

  .search-box .icon {
    font-size: 20px;
  }

  .searchitems-box {
    min-width: 95vw;
  }

  .searchitems-box .container {
    width: 100%;
  }

  .searchitems-box .search-item {
    min-height: 50px;
  }
}

/*# sourceMappingURL=SearchPage.css.map */
