.Event {
  text-align: center;
  margin-top: 15px;
  background-color: #ffff;
  max-width: 95%;
  min-height: 760px;
  padding-bottom: 94px;
}

.Event .month-button {
  background-color: white;
  padding: 5px;
  width: 90px;
  border: 1px solid #99738e;
  border-radius: 4px;
}

.Event .active {
  background-color: #99738e!important;
}

.event-container {
  margin: auto;
  /* background-color: rebeccapurple; */
  position: relative;
  top: 44px;
  max-width: 1200px;
}

.Event  .filter-btn{
  background-color: var(--minor-color);
  color: black;
  border:1px solid var(--minor-color)
}

.Event  .clear-btn{
  background-color:white;
  color: black;
  border:1px solid var(--minor-color)
}
.Event  .link-btn{
     background-color: var(--cta-color);
     color: white;
     padding: 8px 10px 8px 10px ;
     border-radius: 50%;
}