.SettingAndBillingTabs .tabs {
  margin-top: 30px;
}

.SettingAndBillingTabs .tabs .tab-buttons .tab-btn {
  width: calc(100% - 0.5px);
  border: none;
  padding: 5px 10px;
  background-color: #242582;
  color: white;
  font-weight: 600;
}


.SettingAndBillingTabs .tabs .tab-buttons .billing-btn {
  margin-left: 1px;
}

.SettingAndBillingTabs .tabs .tab-buttons .active {
  background-color: #242582;
}

/*# sourceMappingURL=SettingAndBillingTabs.css.map */
