#profilepic {
  display: none;
}

.SettingTabForm {
  min-height: 400px;
  padding: 10px;
}

.SettingTabForm .settingTabForm-img {
  text-align: right;
  margin-top: 30px;
  position: relative;
}

.SettingTabForm .settingTabForm-img button {
  border: none;
  background-color: transparent;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 2px solid #f64c72;
  overflow: hidden;
}

.SettingTabForm .settingTabForm-img .upload-btn {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 5px;
  font-size: 18px;
  box-shadow: 2px 4px 10px lightgrey;
  background-color: #242582;
  color: white;
  padding: 7px;
  border-radius: 50%;
  margin-top: 85px;
}

.SettingTabForm img {
  width: 100%;
  text-align: center;
  height: auto;
}

.SettingTabForm .settingTabForm-img-btn {
  border: none;
  background-color: transparent;
}

.SettingTabForm .setting-form {
  text-align: center;
  margin-top: 30px;
}

.SettingTabForm .changePassword-form {
  margin-top: 30px;
  margin-left: 10px;
  color: black !important;
}

.SettingTabForm .changePassword-form h6 {
  margin-bottom: 10px;
  color: #242582;
  font-weight: 500;
  text-align: left;
  font-size: 18px;
}

.SettingTabForm .changePassword-form p {
  margin-bottom: 20px;
  color: #a1a1a1;
  line-height: 0px;
  text-align: left;
  font-size: 13px;
}

.SettingTabForm input {
  box-shadow: 2px 2px 4px #888888;
  border: 1px solid #d7d7d7;
  padding: 2px 10px;
  width: 100%;
  font-size: 15px;
  letter-spacing: 1px;
  border-radius: 0px !important;
}

.SettingTabForm input:focus {
  padding-left: 10px;
}

::placeholder {
  color: #bdbcbc !important;
}

.SettingTabForm .form-control-select {
  display: block;
  width: 100%;
  color: #75757d !important;
  box-shadow: 2px 2px 4px #888888;
  border: 1px solid #d7d7d7;
  padding: 5px;
  font-size: 15px;
  letter-spacing: 1px;
}

.checkbox {
  text-align: left;
  color: #bdbcbc;
}

#checkbox-input {
  width: 10%;
  cursor: pointer;
  color: #bdbcbc;
  box-shadow: 0px 0px 0px;
}

.SettingTabForm .btn {
  background-color: #f64c72;
  color: white;
  display: block;
  margin: 10px auto;
  padding: 6px;
  letter-spacing: 2px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  border-radius: 0;
  box-shadow: 0px 0px;
  width: 100% !important;
}

.SettingTabForm .btns {
  margin-top: 140px;
}

.SettingTabForm .btn-back {
  background-color: white;
  color: black;
  display: block;
  margin: 10px auto;
  padding: 6px;
  letter-spacing: 2px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  border-radius: 0;
  box-shadow: 0px 0px;
  width: 100% !important;
}

@media (max-width: 800px) {
  .SettingTabForm {
    padding: 20px 30px;
  }

  .SettingTabForm .btns {
    margin-top: 40px;
  }

  .SettingTabForm .settingTabForm-img {
    text-align: center;
    display: table;
    margin: auto;
  }
}

/*# sourceMappingURL=SettingTabForm.css.map */
