.Profile {
  /* margin: auto; */
  padding: 0px;
}

.Profile .my-profile {
  /* margin: auto; */
  padding-top: 20px;
}

.Profile .my-profile-img {
  border: none;
  background-color: transparent;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  /* border: px solid #f64c72; */
  overflow: hidden;
  display: inline-block;
  float: left;
  margin-right: 20px;
}

.Profile .my-profile-img img {
  width: 100%;
  text-align: center;
  height: auto;
}

.Profile .img-btn {
  border: none;
  background-color: transparent;
}

.Profile .profile-data {
  display: inline-block;
}

.Profile .profile-data .heading {
  display: inline-block;
  margin-right: 12px;
  margin-top: 15px;
  margin-bottom: 0px;
  color: #139fa4;
  font-weight: bold;
}

.Profile .profile-data .heading-icon {
  color: #7c7c7c;
  font-size: 26px;
  /* margin-top: 10px; */
  position: relative;
  top: 3px;
}

.Profile .profile-data .email {
  color: #7f7f7f;
  font-weight: bold;
}

.Profile .profile-data .contender {
  color: #139fa4;
  font-weight: bold;
}

.Profile .profile-data i {
  color: #c5c5c5;
  font-size: 20px;
  margin-left: 5px;
}

.Profile .profile-data .medal {
  display: inline-block;
  color: #35adb1;
  font-weight: 500;
  font-size: 20px;
}

.Profile .profile-data .paw {
  display: inline-block;
  margin-left: 20px;
  color: #35adb1;
  font-weight: 500;
  font-size: 20px;
}

.Profile .profile-data .user {
  display: inline-block;
  margin-left: 20px;
  color: #35adb1;
  font-weight: 500;
  font-size: 20px;
}

@media (max-width: 800px) {
  .Profile .profile-data {
    margin-top: 30px;
  }

  .Profile .profile-data .heading {
    margin-right: 12px;
    color: #139fa4;
    font-weight: bold;
    margin-top: 10px;
    font-size: 18px;
  }

  /* .Profile img {
    width: 100px;
    height: 100px;
    border-radius: 66px;
  } */
  .Profile .my-profile-img {
    display: block;
    float: none;
    margin: auto;
  }

  .Profile .profile-data {
    position: relative;
    float: none;
    display: table;
    margin: auto;
    text-align: center;
  }

  .Profile .profile-data .heading-icon {
    font-size: 20px;
    position: absolute;
    top: 12px;
    right: 2px;
  }

  .Profile .profile-data .medal {
    font-size: 17px;
  }

  .Profile .profile-data .paw {
    font-size: 17px;
  }

  .Profile .profile-data .user {
    font-size: 17px;
  }
}

/*# sourceMappingURL=Profile.css.map */
