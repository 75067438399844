.Result {
  background-color: rgba(36,37,130,0.3);
  text-align: left;
  margin: auto;
  position: relative;
}

.Result .Result-img-div {
  width: 65px !important;
  height: 65px !important;
  display: inline-block;
  margin-top: 10px;
  overflow: hidden;
  text-align: center;
  float: left;
  margin-left: 20px;
  border-radius: 50%;
}

.Result .Result-img-div button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  border: none;
  background-color: black;
}

.Result .Result-img-div img {
  margin: 0px !important;
  width: 100% !important;
  height: auto;
}

.Result .hover {
  display: none;
  background-color: var(--dark-color);
  z-index: 12;
  padding-top: 10px;
  transition: all 1s ease-in;
  width: 70px;
  right: -50px;
  text-align: center;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  height: 100%;
  border-radius: 20px 5px 5px 20px;
}

.Result .hover .edit {
  background-color: var(--third-color);
  color: white;
  padding: 1px 5px;
}

.Result:hover .hover {
  display: block;
  position: absolute;
  right: 0px;
  /* display: flex; */
}

.Result .Result-data {
  display: inline-block;
  width: calc(100% - 230px) !important;
  text-align: left;
  position: relative;
  top: 15px;
  left: 10px;
}

.Result .Result-data .heading {
  color: #7c7c7c;
  font-size: 18px;
  margin-bottom: 0%;
}

.Result .Result-data .name {
  display: inline-block;
}

.Result .Result-data .age {
  display: inline-block;
}

.Result .Result-age {
  color: #7e7e7e;
  font-size: 15px;
  font-weight: 600;
}

.Result .Result-medal {
  display: inline-block;
  width: 132px;
  color: #03999e;
  text-align: right;
}

.Result .Result-medal span {
  font-size: 22px;
  font-weight: bold;
}

.Result .Result-medal i {
  font-size: 20px;
  color: #03999e;
  /* margin-right: 10px; */
}

@media screen and (max-width: 800px) {
  .Result {
    min-height: 90px;
  }

  .events-parent {
    margin-top: 10px !important;
  }

  .Result .Result-img-div {
    margin-left: 10px;
  }

  .Result .Result-data {
    margin-top: 0px;
    width: calc(100% - 100px) !important;
  }

  .Result .Result-data p {
    margin-bottom: 0px;
    font-size: 14px;
  }

  .Result .Result-medal {
    display: inline-block;
    width: 111px;
    color: #03999e;
    text-align: right;
    top: 10px;
    position: absolute;
    right: 10px;
  }

  .Result .Result-medal span {
    font-size: 20px;
  }

  .Result .Result-medal i {
    font-size: 18px;
  }
}

/*# sourceMappingURL=Result.css.map */
