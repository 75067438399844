.AddDog .add-dog {
  min-height: 300px;
  width: 90%;
  margin: 40px auto;
}

.AddDog .add-dog-img {
  text-align: right;
  margin-top: 50px;
  position: relative;
}

.AddDog .add-dog-img button {
  border: none;
  background-color: transparent;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 2px solid #f64c72;
  overflow: hidden;
}

.AddDog #profilepic {
  display: none;
}

.AddDog .add-dog-img .upload-btn {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 30px;
  font-size: 18px;
  box-shadow: 2px 4px 10px lightgrey;
  background-color: #242582;
  color: white;
  padding: 7px;
  border-radius: 50%;
  margin-top: 90px;
}

.AddDog .add-dog-img img {
  width: 100%;
  text-align: center;
  height: auto;
}

.AddDog .add-dog-form {
  text-align: center;
  width: 100%;
}

.AddDog .add-dog-form .heading {
  margin: auto;
  color: #f64c72;
  font-weight: bold;
  margin-bottom: 20px;
}

.AddDog .add-dog-form input {
  box-shadow: 2px 2px 4px #888888;
  border: 1px solid #d7d7d7;
  padding: 6px 10px !important;
  width: 100%;
  font-size: 15px;
  letter-spacing: 1px;
  border-radius: 0px !important;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
}

.AddDog .add-dog-form .select {
  box-shadow: 2px 2px 4px #888888;
}

.AddDog .add-dog-form .select input {
  padding: 0px !important;
  margin: 0px !important;
  box-shadow: none !important;
  border: none !important;
}

.AddDog .add-dog-form .add-dog-btn {
  background-color: #f64c72;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  border-radius: 0px;
  margin: 10px auto;
  border: none;
}

@media (max-width: 768px) {
  .add-dog-img {
    width: 100%;
    text-align: center;
  }

  .AddDog .add-dog-form .heading {
    position: relative;
    top: -160px;
  }
}
@media (max-width: 567px) {
  .AddDog .add-dog-img {
    width: 100%;
    text-align: center;
  }

  .AddDog .add-dog-form .heading {
    position: relative;
    top: -160px;
  }
}

/*# sourceMappingURL=AddDog.css.map */
