.Followers {
  min-height: 300px;
  text-align: center;
}

.Followers button {
  background-color: #99738e;
  border: none;
  width: 200px;
  color: #fff;
  padding: 2px;
  text-align: center;
}

.Followers a i {
  margin-top: 20px;
  margin-left: 500px;
  color: #f64c72;
  font-size: 35px;
}

/*# sourceMappingURL=Followers.css.map */
