.AddClubForm{

    /* box-shadow: 2px 3px 10px lightgray; */
    margin:  20px 0px;
    padding:  10px 0px;
    margin-top: 10px;
    border-radius:5px ;

}

.AddClubForm .close-btn{
    float: right;
    padding: 2px;
    padding-top: 4px;
    width: 30px;
    border: none;
    border-radius: 50%;
    height: 30px;
    font-weight: 700;
    /* color: white; */
    /* background-color: #a32517; */
}
.AddClubForm .close-btn:hover{

    color: white;
    background-color: var(--cta-color);
}


@media (max-width: 768px) {

    .AddClubForm select{

        margin-top: 20px;
    }

}