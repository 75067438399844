.Profile {
  padding: 0px;
  position: relative;
}

.Profile .my-profile-img {
  border: none;
  background-color: transparent;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  overflow: hidden;
  display: inline-block;
  float: left;
  margin-right: 20px;
}

.Profile .my-profile-img img {
  width: 100%;
  text-align: center;
  height: auto;
}

.Profile .img-btn {
  border: none;
  background-color: transparent;
}

.Profile .profile-data {
  display: inline-block;
}

.Profile .profile-data .heading {
  display: inline-block;
  margin-right: 12px;
  margin-top: 15px;
  margin-bottom: 0px;
  color: #242582;
  font-weight: bold;
}

.Profile .profile-data .heading-icon {
  color: #7c7c7c;
  font-size: 26px;
  position: relative;
  top: 3px;
}

.Profile .profile-data .email {
  color: #7f7f7f;
  font-weight: bold;
}

.Profile .profile-data .contender {
  color: #242582;
  font-weight: bold;
}

.Profile .profile-data i {
  color: #c5c5c5;
  font-size: 20px;
  margin-left: 5px;
}

.Profile .profile-data .medal {
  display: inline-block;
  color: #242582;
  font-weight: 500;
  font-size: 20px;
}

.Profile .profile-data .paw {
  display: inline-block;
  margin-left: 20px;
  color: #242582;
  font-weight: 500;
  font-size: 20px;
}

.Profile .profile-data .user {
  display: inline-block;
  margin-left: 20px;
  color: #242582;
  font-weight: 500;
  font-size: 20px;
}

.Profile .follow-btn {
  display: inline-block;
  width: 130px;
  float: right;
  margin-top: -114px;
}

.follow-btn .btn {
  background-color: #f64c72;
  font-weight: 500;
  border-radius: 6px;
  border: none;
  outline: none;
  color: #fff;
  padding: 1px 25px;
}

.followed {
  background-color: gray !important;
}

.follow-btn .btn:visited {
  background-color: #f64c72;
}

.follow-btn .btn-small {
  display: none;
}

@media (max-width: 800px) {
  .Profile .profile-data .heading {
    margin-right: 12px;
    color: #242582;
    font-weight: bold;
    margin-top: 10px;
    font-size: 18px;
  }

  .Profile .my-profile-img {
    display: block;
    float: none;
    margin: auto;
  }

  .Profile .profile-data {
    position: relative;
    float: none;
    display: table;
    margin: auto;
    text-align: center;
  }

  .Profile .profile-data .heading-icon {
    font-size: 20px;
    position: absolute;
    top: 12px;
    right: 2px;
  }

  .Profile .profile-data .medal {
    font-size: 17px;
  }

  .Profile .profile-data .paw {
    font-size: 17px;
  }

  .Profile .profile-data .user {
    font-size: 17px;
  }
}
@media (max-width: 800px) {
  .follow-btn {
    width: 30px;
    top: 20px;
    right: 10px;
  }

  .follow-btn .btn {
    display: none;
  }

  .follow-btn .btn-small {
    display: block;
    background-color: #f64c72;
    font-weight: 500;
    border-radius: 50%;
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    line-height: 0em;
    font-size: 20px;
    color: #fff;
    padding: 0px 1px;
    position: absolute;
    top: 10px;
    z-index: 1;
    right: 10px;
  }
}

/*# sourceMappingURL=UserProfile.css.map */
