.LoggedInUpcommingCompetitions {
  background-color: #99738e;
  padding-top: 10px;
  min-height: 400px;
  text-align: left;
  padding: 20px;
}

.LoggedInUpcommingCompetitions .form {
  color: #343a40;
  margin-bottom: 10px;
  margin: auto 30px;
}

.LoggedInUpcommingCompetitions .content {
  margin: auto 30px;
}

.LoggedInUpcommingCompetitions .content-item .name, .LoggedInUpcommingCompetitions .content-item .date {
  font-weight: 700;
  margin-bottom: 6px;
}

.LoggedInUpcommingCompetitions .heading {
  color: #ffffff;
  padding: 5px;
  text-align: center;
  margin: 20px auto;
  padding: 5px;
  font-size: 20px;
}

.LoggedInUpcommingCompetitions form {
  min-height: 50px;
}

.LoggedInUpcommingCompetitions select {
  border: none;
  cursor: pointer;
  outline: 0;
  width: 100%;
  padding: 7px;
  margin-bottom: 20px;
}

/*# sourceMappingURL=LoggedInUpcommingCompetitions.css.map */
