.AddMultiClubs .club-information {
  min-height: 300px;
  width: 90%;
  margin: 40px auto;
  margin-top: 20px;
}


.AddMultiClubs .club-information .club-info-img {
  text-align: right;
  margin-top: 50px;
  position: relative;
  width: 120px;
  height: 120px;
  float: right;
  margin-right: 30px;
  overflow: hidden;
  border-radius: 66px;
}

.AddMultiClubs .club-information .club-info-img button {
  height: 100%;
}

.AddMultiClubs .club-information .club-info-img img {
  width: 100%;
  height: auto;
  text-align: center;
}

.AddMultiClubs .club-information .club-info-img button {
  border: none;
  background-color: transparent;
}

.AddMultiClubs .club-information .club-info-para {
  text-align: left;
  width: 100%;
}

.AddMultiClubs .club-information .club-info-para .heading {
  text-align: left!important;
  color: var(--cta-color);
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 10px;
}

.AddMultiClubs .club-information .club-info-para .club-info-para-1 {
  font-size: 13px;
}

.AddMultiClubs .club-information .club-info-para .club-info-para-2 {
  font-size: 13px;
}

.AddMultiClubs .club-information .club-info-para .info-btn {
  background-color: #c4c4c4;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  padding: 1px 10px;
  border: none;
  border-radius: 4px;
}

.AddMultiClubs .club-information .club-info-para .info-btn i {
  margin-right: 5px;
}

.AddMultiClubs .club-info-form .form-control-select {
  display: block;
  width: 65%;
  margin-top: 5px;
  color: #75757d !important;
  box-shadow: 2px 2px 4px #888888;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  padding: 6px 6px !important;
  font-size: 15px;
  letter-spacing: 1px;
  color: black !important;
  outline: none;
}

.AddMultiClubs .club-info-form .form-control-select option {
  color: #858585;
}

.AddMultiClubs .club-info-form-2 {
  margin-top: 20px;
}

.AddMultiClubs .club-info-form #name {
  width: 100%;
  color: #75757d !important;
  box-shadow: 2px 2px 3px #888888;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  padding: 5px 5px !important;
  color: black !important;
  
  outline: none;
}

.AddMultiClubs .club-info-form #number {
  width: 100%;
  color: #75757d !important;
  box-shadow: 2px 2px 3px #888888;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  padding: 5px 5px !important;
  color: black !important;
  outline: none;
}

.AddMultiClubs .club-information .club-info-para .club-info-btn {
  display: block;
  width: auto!important;
  background-color: var(--cta-color);
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 26px;
  border: none;
  margin-top: 40px;
  border-radius: 5px;
  letter-spacing: 1px;
  float: right;
}

.AddMultiClubs .club-information .club-info-para .club-info-btn-back {
  display: block;
  /* width: 90%; */
  background-color: transparent;
  color: black;
  font-size: 15px;
  font-weight: 600;
  /* margin: 100px auto; */
  padding: 3px 10px;
  border: none;
  border-radius: 0px;
  float: right;
  padding: 10px 26px;
  margin-top: 40px;
  letter-spacing: 1px;
}




.club-divider{

  width: 60%;
  height: 3px;
  background-color: var(--primary-color);
  opacity: 0.3;
margin: auto;
}


@media (max-width: 768px) {
 
  .AddMultiClubs .club-info-form{
    position: relative;
  }
 
  .AddMultiClubs .club-information .club-info-img {
    width: 100%;
    text-align: center;
  }

  .AddMultiClubs .club-information .club-info-para .heading {
    position: relative;
    top: -160px;
  }

  .AddMultiClubs .btn-container{
    text-align: center;
    float: none;
  }

  .AddMultiClubs .club-info-form .form-control-select {
    width: 100%;
    margin-top: 40px!important;
  }

  .AddMultiClubs .club-info-form #name {
    margin: auto;
    margin-bottom: 20px;
  
  }

  .AddMultiClubs .club-info-form #number {
    margin: auto;
  }
}
@media (max-width: 567px) {
  .AddMultiClubs .club-information .club-info-img {
    width: 100%;
    text-align: center;
  }

  .AddMultiClubs .club-information .club-info-para .heading {
    position: relative;
    top: -160px;
  }

  .AddMultiClubs .club-info-form .form-control-select {
    width: 100%;
  }

  .AddMultiClubs .club-info-form #name {
    margin: auto;
  }

  .AddMultiClubs .club-info-form #number {
    margin: auto;
  }
}
