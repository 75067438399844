.Posts {
  max-height: 650px;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (max-width: 700px) {
  .Posts {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/*# sourceMappingURL=Posts.css.map */
