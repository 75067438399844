.EventsAndResults {
  min-height: 300px;
  width: 90%;
  margin: auto;
}

.EventsAndResults .events-parent {
  overflow: hidden;
}

.EventsAndResults .add-btn {
  background-color: transparent;
  border: none;
  margin-top: 20px;
  position: relative;
  float: right;
}

.EventsAndResults .add-btn i {
  color: #f64c72;
  font-size: 35px;
}

.EventsAndResults .para {
  color: #5d5c5c;
  text-align: center;
  margin-top: 50px;
}

@media only screen and (max-width: 567px) {
  .EventsAndResults {
    width: 90%;
  }
}

/*# sourceMappingURL=EventsAndResults.css.map */
