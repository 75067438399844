.add-result {
  min-height: 300px;
  width: 90%;
  margin: 20px auto;
}

.add-result-form {
  margin-top: 20px;
}

.add-result .form-control-select {
  display: block;
  width: 100%;
  color: #75757d !important;
  box-shadow: 2px 2px 4px #888888;
  border: 1px solid #d7d7d7;
  padding: 5px;
  font-size: 15px;
  letter-spacing: 1px;
  color: #bdbcbc !important;
  outline: none;
  margin-bottom: 12px;
}

.add-result .add-result-data .heading {
  text-align: left;
  color: #f64c72;
  font-weight: bold;
  margin-bottom: 20px;
}

.result-btn {
  display: block;
  width: 95%;
  background-color: #f64c72;
  color: #fff;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  padding: 10px 0px;
  position: absolute;
  bottom: 0;
  border: none;
  border-radius: 0px;
  letter-spacing: 1px;
}

 .result-btn-back {
  display: block;
  width: 100%;
  background-color: white;
  color: black;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  padding: 20px 0px;
  border: none;
  border-radius: 0px;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .add-result .add-result-data .heading {
    text-align: center;
    position: relative;
  }
}

/*# sourceMappingURL=AddResult.css.map */
